import type { PageFooterWidgetConfig } from './type';

export const PAGE_FOOTER_CONFIG: PageFooterWidgetConfig = {
    linkLists: [
        {
            title: 'frontend.footer.links.title-about-us',
            links: [
                {
                    label: 'frontend.footer.about-us.about-us',
                    href: '/info/',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'about_us',
                    },
                },
                {
                    label: 'frontend.footer.about-us.career',
                    href: 'https://www.olxgroup.com/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'career',
                    },
                },
                {
                    label: 'frontend.footer.about-us.finance',
                    href: 'https://creditare.storia.ro/',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'finance_and_mortgages',
                    },
                },
                {
                    label: 'frontend.footer.about-us.legal-information',
                    href: 'https://help.storia.ro/storiahelp/s/topic/0TO090000009twJGAQ/informa%C8%9Bii-juridice',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'legal_information',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-professionals',
            links: [
                {
                    label: 'frontend.footer.professionals.price-list',
                    href: '/[lang]/preturi',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'price_list',
                    },
                },
                {
                    label: 'frontend.footer.professionals.sell-with',
                    href: 'https://publicitate.olx.ro/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'sell_with_storia',
                    },
                },
                {
                    label: 'frontend.footer.professionals.tips',
                    href: '/blog/ghid-vanzare ',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'tips',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-knowledge-hub',
            links: [
                {
                    label: 'frontend.footer.knowledge-hub.index-platform',
                    href: 'https://trai.storia.ro/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'index_platform',
                    },
                },
                {
                    label: 'frontend.footer.knowledge-hub.articles-and-tips',
                    href: '/blog/',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'articles_and_tips',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-olx-group',
            links: [
                {
                    label: 'frontend.footer.olx-group.olx',
                    href: 'https://www.olx.ro/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'olx.ro',
                    },
                },
                {
                    label: 'frontend.footer.olx-group.autovit',
                    href: 'https://www.autovit.ro/',
                    rel: 'nofollow',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'autovit.ro',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-sitemap',
            links: [
                {
                    label: 'frontend.footer.sitemap.sitemap',
                    href: '/[lang]/harta-site',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'map_by_category',
                    },
                },
                {
                    label: 'frontend.footer.sitemap.location-map',
                    href: '/[lang]/harta-site/regiuni/apartament',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'map_by_location',
                    },
                },
            ],
        },
        {
            title: 'frontend.footer.links.title-contact',
            links: [
                {
                    label: 'frontend.footer.contact.custom-service',
                    href: 'https://help.storia.ro/storiahelp/s/contactsupport',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'contact_support',
                    },
                },
                {
                    label: 'frontend.footer.about-us.help-center',
                    href: 'https://help.storia.ro/',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'help_center',
                    },
                },
                {
                    label: 'frontend.footer.about-us.digital-service-act',
                    href: 'https://help.storia.ro/storiahelp/s/topic/0TO09000000kiXCGAY/digital-services-act-dsa',
                    target: '_blank',
                    tracking: {
                        eventName: 'footer_link_click',
                        touchPointButton: 'dsa_footer',
                    },
                },
            ],
        },
    ],
    consentLinks: [
        {
            label: 'frontend.footer.consent.condition-of-use',
            href: 'https://help.storia.ro/storiahelp/s/topic/0TO09000000kiXAGAY/',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'condition_of_use',
            },
        },
        {
            label: 'frontend.footer.consent.privacy-policy',
            href: 'https://help.storia.ro/storiahelp/s/article/ka009000001weLiAAI',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'privacy_policy',
            },
        },
        {
            label: 'frontend.footer.consent.cookie-settings',
            button: 'cookieButton',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'cookie_settings',
            },
        },
    ],
    socialLinks: [
        {
            icon: '/images/pageFooter/socialIcons/facebook.svg',
            href: 'https://www.facebook.com/storia.ro',
            title: 'frontend.footer.links.social-facebook',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'facebook',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/youtube.svg',
            href: 'https://www.youtube.com/c/Storiaro',
            title: 'frontend.footer.links.social-youtube',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'youtube',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/instagram.svg',
            href: 'https://www.instagram.com/storia_ro/',
            title: 'frontend.footer.links.social-instagram',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'instagram',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/tiktok.svg',
            href: 'https://www.tiktok.com/@storia_ro',
            title: 'frontend.footer.links.social-tiktok',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'tiktok',
            },
        },
        {
            icon: '/images/pageFooter/socialIcons/linkedin.svg',
            href: 'https://www.linkedin.com/company/79635350',
            title: 'frontend.footer.links.social-linkedin',
            target: '_blank',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'linkedin',
            },
        },
    ],
    mobileAppsMarkets: {
        googlePlay: {
            logo: {
                src: '/images/pageFooter/mobileAppsMarkets/google_play.svg',
                width: 120,
                height: 40,
            },
            appUrl: 'https://play.google.com/store/apps/details?id=ro.storia&referrer=utm_source%3Dstoria.ro%26utm_medium%3Dcpc%26utm_campaign%3Dandroid-app-footer',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'google_play',
            },
        },
        appStore: {
            logo: {
                src: '/images/pageFooter/mobileAppsMarkets/app_store.svg',
                width: 131,
                height: 40,
            },
            appUrl: 'https://apps.apple.com/app/storia/id1121617948',
            tracking: {
                eventName: 'footer_link_click',
                touchPointButton: 'app_store',
            },
        },
    },
    logo: {
        src: '/images/logo/storia_logo_black.svg',
        width: 108,
        height: 30,
    },
};
